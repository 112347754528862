import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Nvbar from './components/Nvbar';
import Home from './components/Home';
import WalletConnect from './components/WalletConnect';
import Footer from './components/Footer';

const OffChain = () => {

  return (
    
    <BrowserRouter>
      <div className="home">
        <Nvbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path='/realestate' component={WalletConnect} />
          <Route exact path='/dex' component={WalletConnect} />
        </Switch>
        <Footer /> 
      </div>
    </BrowserRouter>
  
  );
}

export default OffChain;
