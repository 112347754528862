import React from 'react';
import { Row, Col } from 'react-bootstrap';

const WalletConnect = () => {
  return (

    <Row className='justify-content-md-center connect-font'>
      <Col xs={12} sm={3} md={3}>
        Connect to Wallet
      </Col>
    </Row>

  );
};

export default WalletConnect;
