const address = {

  // RealEstate
  lhostReDate: '10/08/2021',
  lhostDaiAddr: '0xb7278A61aa25c888815aFC32Ad3cC52fF24fE575',
  lhostReAddr: '0xCD8a1C3ba11CF5ECfa6267617243239504a98d90',
 
  kovanReDate: '06/05/2021',
  kovanDaiAddr: '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa', 
  kovanReAddr: '0x0f6Ad308a24039001a9D7265eaAc50b52fe10501', 
  
  rinkebyReDate: '23/05/2021',
  rinkebyDaiAddr: '0xc3dbf84Abb494ce5199D5d4D815b10EC29529ff8', // 0xc7ad46e0b8a400bb3c915120d284aafba8fc4735 
  rinkebyReAddr: '0xCfC5c1b202D6c66313F14C6aD8eea99CFFA5D713', // 0xDc6afD39B3f7096E20d398d2F2ff698F85526bdb

  bscTestReDate: '23/05/2021',
  bscTestDaiAddr: '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867', 
  bscTestReAddr: '0x3e80BbD75d80e2B2A482A90ba14f1A8BB4397681',

  // Dex
  lhostDexDate: '10/08/2021',
  lhostBatAddr: '0x922D6956C99E12DFeB3224DEA977D0939758A1Fe', 
  lhostDarAddr: '0x5081a39b8A5f0E35a8D959395a630b68B74Dd30f', 
  lhostRepAddr: '0x1fA02b2d6A771842690194Cf62D91bdd92BfE28d', 
  lhostZrxAddr: '0xdbC43Ba45381e02825b14322cDdd15eC4B3164E6', 
  lhostDexAddr: '0x04C89607413713Ec9775E14b954286519d836FEf',

  kovanDexDate: '06/05/2021',
  kovanBatAddr: '0xF1D9DCDb463a1B806b8C856E0B833755b1c2149a', 
  kovanDarAddr: '0x8A209656A0128a0B2d8a4177f75070900f0DF3b8', 
  kovanRepAddr: '0x996DD7d47Cb9d18a8a40Ca1D0f48753E8A028812', 
  kovanZrxAddr: '0x34a461D4CE4881dA30Eef686Fe9b5230758483B3', 
  kovanDexAddr: '0x9D71b489Ec02f81E4c0eb3561A32B3AeA95aDdE8', 

  rinkebyDexDate: '23/05/2021',
  rinkebyBatAddr: '0x1ad2eb0f7a0503EfD090cb1088cF63B748Be80D9', 
  rinkebyDarAddr: '0x579e134284c1E6ad92c06061cb6569158C19f67E', 
  rinkebyRepAddr: '0xE12a8B1e0182093c74237c9E8462b14d61bDf901', 
  rinkebyZrxAddr: '0x502B3A2897232AEcd9aEb8193c8885Cc78fc1c9C', 
  rinkebyDexAddr: '0x6021A1cD5d37cBcaB8DBF8649ecB15C55EefF975', 
 
  bscTestDate: '23/05/2021',
  bscTestBatAddr: '0x1ad2eb0f7a0503EfD090cb1088cF63B748Be80D9', 
  bscTestDarAddr: '0x579e134284c1E6ad92c06061cb6569158C19f67E', 
  bscTestRepAddr: '0xE12a8B1e0182093c74237c9E8462b14d61bDf901', 
  bscTestZrxAddr: '0x502B3A2897232AEcd9aEb8193c8885Cc78fc1c9C', 
  bscTestDexAddr: '0x6021A1cD5d37cBcaB8DBF8649ecB15C55EefF975'

};

export default address;